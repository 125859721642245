import { createStore } from 'vuex'

import  eventDetails  from './eventDetails'

import createVuexAlong from "vuex-along";




export default createStore({
  state:{networkState:true},
  getters:{
    getNetworkState:(state)=>state.networkState
  },
  mutations:{
    networkState:(state,status:boolean)=>{
        state.networkState=status
    }
  },
  actions:{
    actionsNetworkState( {commit} , status: boolean) {
      commit('networkState', status)},
  },

  modules: {
    eventDetails
  },
  plugins: [
    createVuexAlong ({
        name: "zhangdll", // 设置保存的集合名字，避免同站点下的多项目数据冲突
        // local: {
        //     list: ["address"],
        //     isFilter: true // 过滤模块 address数据， 将其他的存入 localStorage
        // },
        // session: {
        //     list: ["address.city"] // 保存 address.city到 sessionStorage
        // }
        // //如果对于sessionstorage不进行任何操作，也可将session设为false
    })
]

});