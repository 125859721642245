import { jsonp } from "vue-jsonp";
import { storage } from "@/storage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data: any, id: any, parentId: any, children: any) {
  let config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  };

  var childrenListMap: any = {};
  var nodeIds: any = {};
  var tree: any = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o: any) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
}


export function base64toFile(base64: string, filename: string) {
  var arr = base64.split(','), mime = (arr[0].match(/:(.*?);/) as any)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

//计算坐标
export function locationSum(lat1: number, lng1: number, lat2: number, lng2: number) {
  var radLat1 = lat1 * Math.PI / 180.0;
  var radLat2 = lat2 * Math.PI / 180.0;
  var a = radLat1 - radLat2;
  var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137; // 地球半径
  s = Math.round(s * 10000) / 10000; // 输出为公里
  // s = s.toFixed(2);
  return s;
}


//微信SDK注入
export function wxReg(urlLink: string) {
  jsonp('https://qywx.xiya3333.com/weixin-cp/sign/jsapi', {
    agentid: "1000025",
    url: urlLink,
  }).then(data => {
    storage.set("accessToken", data.accessToken)
    wx.config({
      // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      debug: false,
      // 必填，公众号的唯一标识
      appId: data.appId,
      // 必填，生成签名的时间戳
      timestamp: data.timestamp,
      // 必填，生成签名的随机串
      nonceStr: data.nonceStr,
      // 必填，签名，见附录1
      signature: data.signature,
      // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      jsApiList: ["checkJsApi", "scanQRCode", "hideMenuItems", 'onMenuShareWechat', 'updateTimelineShareData', 'showOptionMenu', 'shareWechatMessage', 'shareAppMessage', 'onMenuShareAppMessage', 'getLocation', 'selectEnterpriseContact', 'previewFile']
    });
    wx.agentConfig({
      debug: true,
      corpid: data.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
      agentid: '1000025', // 必填，企业微信的应用id （e.g. 1000247）
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonceStr, // 必填，生成签名的随机串
      signature: data.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
      jsApiList: ['selectExternalContact',"scanQRCode", "checkJsApi", "hideMenuItems", 'onMenuShareWechat', 'updateTimelineShareData', 'showOptionMenu', 'shareWechatMessage', 'shareAppMessage', 'onMenuShareAppMessage', 'getLocation'], //必填，传入需要使用的接口名称
      success: function (res: any) {
        // 回调
      },
      fail: function (res: any) {
        if (res.errMsg.indexOf('function not exist') > -1) {
          alert('版本过低请升级')
        }
      }
    });
  })
  wx.error(function (res: any) {
    alert("出错了：" + res.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
  });

  wx.ready(function () {
    wx.hideMenuItems({
      menuList: ["menuItem:share:timeline", "menuItem:copyUrl", "menuItem:favorite", "menuItem:favorite", "menuItem:openWithSafari", "menuItem:share:wechat"] // 要隐藏的菜单项
    });

  });




}

//获取当前日期
export function currentDate() {
  let y = new Date().getFullYear() + '-'
  let m = (new Date().getMonth() + 1 < 10) ? '0' + (new Date().getMonth() + 1) + '-' : (new Date().getMonth() + 1) + '-'
  let d = (new Date().getDate() < 10) ? '0' + new Date().getDate() : new Date().getDate()
  let currentDate = y + m + d
  return currentDate
}     


// 防抖：防抖指的是在事件触发n秒后再执行回调，如果在n秒内再次被触发，则重新计算时间。
// 为什么需要防抖：就拿用户登录来说，如果不做防抖操作，多次点击登入按钮就会多次请求后台，
// 既会造成不必要的性能消耗也会造成用户体验的不好
// 防抖在连续的事件，只需触发一次回调的场景有：
// 搜索框搜索输入。只需用户最后一次输入完，再发送请求。
// 窗口大小的改变resize。只需窗口调整完成后，计算窗口大小。防止重复渲染。
// 登录、发短信等按钮避免用户点击太快，以致于发送了多次请求，需要防抖。
// 实现过程：封装一个函数并传递两个参数，
// 第一个参数是需要调用的函数或者请求，第二个参数是延迟多少毫秒才执行函数
// 在函数里先定义一个空变量，再return一个匿名函数，在该函数中先判断定义的变量是否为空，
// 为空就添加一个定时器，不为空就清除定时器
// fn代表有个函数，里面可能会频繁发生回调或ajax
// delay表示间隔多少毫秒才触发，默认200毫秒
export function debounce(fn:Function,delay=200){//fn是需要防抖的函数，delay是延迟多少毫秒执行fn
  let timer:any = null;
  return function (){
      if(timer){
          clearTimeout(timer);
      }
      timer=setTimeout(()=>{
          fn.apply(fn,arguments);
          timer = null;
      },delay)   
  }
}


// 节流：节流是指如果持续触发某个事件，则每隔n秒执行一次
// fn代表可能会频繁发生回调或ajax请求的函数
// delay表示间隔多少毫秒才触发，默认100毫秒
// 节流在间隔一段时间执行一次回调的场景有：
// 滚动加载，加载更多或滚到底部监听
// 搜索框，搜索联想功能
// 实现过程：封装一个函数并传递两个参数，
// 第一个参数是需要调用的函数或者请求，第二个参数是延迟多少毫秒才执行函数
// 在函数里先定义一个空变量，再return一个匿名函数，在该函数中先判断定义的变量是否为空，
// 为空就执行定时器，不为空就不执行定时器而不是像防抖一样清除定时器
export function throttle(fn:Function,delay=100){
  //首先设定一个变量，在没有执行我们的定时器为null
  let timer:any = null;
  return function (){
      //当我们发现这个定时器存在时，则表示定时器已经在运行中，需要返回
      if(timer) return;
      timer =setTimeout(()=>{
          fn.apply(fn,arguments);
          timer = null;
      },delay)
  }
}