import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */'@/views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: '/my',
    name: 'My',
    component: () => import(/* webpackChunkName: "my" */ '@/views/My.vue')
  },

  {
    path: '/work',
    name: 'Work',
    component: () => import(/* webpackChunkName: "my" */ '@/views/Work.vue')
  },

  {
    path: '/detailed',
    name: 'Detailed',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/Detailed.vue')
  },
  {
    path: '/workreport',
    name: 'WorkReport',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/WorkReport.vue')
  },
  {
    path: '/sale',
    name: 'Sale',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/Sale.vue')
  },

  {
    path: '/salefilling',
    name: 'SaleFilling',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/SaleFilling.vue')
  },
  {
    path: '/replenishment',
    name: 'Replenishment',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/Replenishment.vue')
  },
  {
    path: '/ordercheck/:id',
    name: 'OrderCheck',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/OrderCheck.vue')
  },
  {
    path: '/orderfind',
    name: 'OrderFind',

    // 路由独享守卫
    // beforeEnter: (to, from) => {
    //   to.meta.isKeepAlive = to.name === 'OrderFind' && from.name == 'OrderCheck' ? true : false
    //   return true
    // },

    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/OrderFind.vue')
  },
  {
    path: '/addTargetSale',
    name: 'AddTargetSale',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/AddTargetSale.vue')
  },
  {
    path: '/replenishmentbh',
    name: 'Replenishmentbh',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/ReplenishmentBH.vue')
  },
  {
    path: '/replenishmentcheck/:id',
    name: 'Replenishmentcheck',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/ReplenishmentCheck.vue')
  },
  {
    path: '/ReplenishmentFind',
    name: 'ReplenishmentFind',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/ReplenishmentFind.vue')
  },
  {
    path: '/OrderBuilder',
    name: 'OrderBuilder',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/OrderBuilder.vue')
  },

  {
    path: '/saleurl/:uri',
    name: 'saleurl',
    component: () => import(/* webpackChunkName: "Workdetails" */ '@/views/SaleUri.vue')
  },


  


  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})

export default router
