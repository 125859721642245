import { Commit } from "vuex";
import { IDetailed } from "./interface";

const state = {
    eventDetails: <IDetailed>Array
}
const getters = {
    //获取事项
    getEventDetails: (state: any) => state.eventDetails
}


const mutations = {
    //将事项明细保存在store
    setEventDetails(state: any, data: Array<IDetailed>) {
        state.eventDetails = data
    },
    //删除已完成的事项
    removeEventDetails(state: any, workflow: any) {
        state.eventDetails[workflow.Index].children.forEach((e: any) => {
            if (Array.isArray(e.children)) {
                let arr = e.children.filter((e: any) => {
                    return e.workflowId != workflow.Id
                })
                e.children = arr
            }
        });
    }
}
const actions = {
    //将事项明细保存在store
    actionsEventDetails({ commit }: { commit: Commit }, data: Array<IDetailed>) {
        commit('setEventDetails', data)
    },
    //删除已完成的事项
    actionsremoveEventDetails({ commit }: { commit: Commit }, data: any) {
        commit('removeEventDetails', data)
    },
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
